// AUTH
export const SANCTUM_CSRF_COOKIE = '/sanctum/csrf-cookie'
export const LOGIN = '/login'
export const LOGOUT = '/logout'
export const REGISTER = '/register'
export const FORGOT_PASSWORD = '/forgot-password'
export const RESET_PASSWORD = '/reset-password'
export const EMAIL_VERIFICATION_NOTIFICATION = '/email/verification-notification'

// USERS
export const USERS_CRUD = '/api/v1/users'
export const ROLES_CRUD = '/api/v1/roles'
export const USER_PROFILE = '/api/v1/profile'
export const PERMISSIONS_CRUD = '/api/v1/permissions'
export const ADDRESSES_CRUD = '/api/v1/addresses'
export const ADDRESSES_ALL = '/api/v1/addresses/get-all-addresses'
export const CITIES_CRUD = '/api/v1/cities'
export const CUSTOMERS_CRUD = '/api/v1/customers'
export const CUSTOMER_ALL = '/api/v1/customers/get-all-customers'
export const DIAGNOSES_CRUD = '/api/v1/diagnoses'
export const MACHINES_CRUD = '/api/v1/machines'
export const MACHINES_LIST = '/api/v1/machines/list-machines'
export const MACHINE_TYPES_CRUD = '/api/v1/machine-types'
export const MEASUREMENT_INSTRUMENTS_CRUD = '/api/v1/measurement-instruments'
export const ASSAYS_CRUD = '/api/v1/assays'
export const ASSAY_SCHEDULE_STORE = '/api/v1/assays/store/scheduled'
export const ASSAY_SCHEDULE = '/api/v1/assays/scheduled'
export const CAPACITIES_CRUD = '/api/v1/capacities'
export const ECAS_CRUD = '/api/v1/ecas'
export const OHM_RESISTANCES_CRUD = '/api/v1/ohm-resistances'
export const IRCS_CRUD = '/api/v1/ircs'
export const INDEXES_CRUD = '/api/v1/indexes'
export const MCAS_CRUD = '/api/v1/mcas'
export const SVS_CRUD = '/api/v1/svs'
export const ASSAY_CALCULATIONS = 'api/v1/assay-calculations'
export const AUDITS = 'api/v1/audits'
export const ASSAY_SQUEDULED = 'api/v1/assays/scheduled'
export const ASSAY_DATA = 'api/v1/customers/get-assay-data'