import { useEffect, useState } from "react"
import useAxios from "axios-hooks"
import UserContext from "../contexts/UserContext"
import { USER_PROFILE } from "../constants/api"

const AuthenticatedUser = ({ children }) => {

    const [isLoggedIn, setIsLoggedIn] = useState(null)
    const [userData, setUserData] = useState(null)
    const [{ response, error }] = useAxios(USER_PROFILE)

    useEffect(() => {
        if (response && response.status === 200) {
            const data = { ...response.data.data, isLoggedIn: true }
            setUserData(data)
            setIsLoggedIn(true)
        } else {
            setUserData({ isLoggedIn: false })
        }
    }, [response])

    useEffect(() => {
        if (error && error.response.status === 401) {
            setIsLoggedIn(false)
        }
    }, [error])

    return isLoggedIn === null ? null : <UserContext.Provider value={{ userData, setUserData }}>
        {children}
    </UserContext.Provider>
}

export { AuthenticatedUser }