import { defineAbility, subject } from '@casl/ability'
import { initialAbility } from './initialAbility'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this

const userData = JSON.parse(localStorage.getItem('userData'))

const permissionExtractor = (arrayPermissions) => {
  return arrayPermissions.map((item) => {
    return { action: item.name.split('_')[0], subject: item.name.split('_')[1] }
  })
}

const existingAbility = userData?.permissions ? permissionExtractor(userData?.permissions[0]) : initialAbility

const ability = defineAbility((can, cannot) => {

  existingAbility.forEach(abi => {
    can(abi.action, abi.subject)
  });

});

export default ability