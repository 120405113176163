// ** Initial user ability
export const initialAbility = [
  {
    // action: 'manage',
    action: 'read',
    // subject: 'all'
    subject: 'home'
  }
]

export const _ = undefined
